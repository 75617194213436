import Vue from 'vue';
import Parent from './Parent.vue';
import routes from './router/parent';
import store from './store/parent';
import vuetify from './plugins/vuetify';
import GlobalAlert from './plugins/global-alert';
import i18n from './i18n';
import VueHead from 'vue-head';
import VueRouter from 'vue-router';
import { authGuard } from '@/services/auth';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;
Vue.use(GlobalAlert);
Vue.use(VueHead);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(authGuard(store));

if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_PARENT_GOOGLE_ANALYTICS_ID) {
  let includes = null;
  if (process.env.VUE_APP_VIDEO_GOOGLE_ANALYTICS_ID) {
    includes = [{ id: process.env.VUE_APP_VIDEO_GOOGLE_ANALYTICS_ID }];
  }
  Vue.use(
    VueGtag,
    {
      includes: includes,
      config: { id: process.env.VUE_APP_PARENT_GOOGLE_ANALYTICS_ID },
      boostrap: false,
    },
    router
  );
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(Parent),
}).$mount('#app');
