import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/overview',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "parent-login" */ '../views/common/LoginPage.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/parent/ResetPasswordPage.vue'),
  },
  {
    path: '/set-password',
    name: 'set-password',
    component: () => import(/* webpackChunkName: "set-password" */ '../views/parent/SetPasswordPage.vue'),
  },
  {
    path: '/overview',
    name: 'overview',
    component: () => import(/* webpackChunkName: "parent-overview" */ '../views/parent/OverviewPage.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "parent-about" */ '../views/parent/AboutParent.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "parent-account" */ '../views/parent/AccountPage.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: () => import(/* webpackChunkName: "parent-account" */ '../views/parent/UnsubscribePage.vue'),
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "parent-redirect" */ '@/components/common/PostOAuthRedirect.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "common-logout" */ '../views/common/LogoutPage.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "common-error" */ '@/views/common/ErrorPage.vue'),
  },
  {
    path: '*',
    redirect: '/error',
  },
];

export default routes;
