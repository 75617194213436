import { Member } from '@/models/member';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { makeRequest } from '@/services/api-request';
import { AuthResponse } from '@/models/authResponse';

/**
 * List state values for the tags related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - ListState
 */
const getDefaultState = () => ({
  children: [] as Array<Member>,
  dismissedAwardBanners: [] as Array<string>,
});

const state = getDefaultState();

type ParentState = typeof state;

const getDismissedAwardBanners = () => {
  try {
    const str = localStorage.getItem('dismissedAwardBanners');
    return str ? JSON.parse(str) : null;
  } catch {
    return null;
  }
};

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  resetState: (moduleState: ParentState) => {
    Object.assign(moduleState, getDefaultState());
    localStorage.removeItem('dismissedAwardBanners');
  },
  updateChildren: (moduleState: ParentState, data: Array<Member>) => {
    moduleState.children = data || [];
  },
  addDismissedAwardBanner: (moduleState: ParentState, key: string) => {
    moduleState.dismissedAwardBanners.push(key);
    let banners = moduleState.dismissedAwardBanners;
    const dismissed = getDismissedAwardBanners();
    if (dismissed) {
      banners = banners.concat(dismissed);
    }
    // Persist dismissed banners if you refresh the page
    localStorage.setItem('dismissedAwardBanners', JSON.stringify(banners));
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 *
 * @property actions - Object
 */
const actions = {
  resetState: async (context: ActionContext<ParentState, RootState>): Promise<void> => {
    context.commit('resetState');
  },
  /**
   * Fetches children
   *
   * @param {ActionContext} context
   * @return void
   */
  fetchChildren: async (context: ActionContext<ParentState, RootState>, parentId: string) => {
    const queryParams: Record<string, string> = {
      page: '0',
      with_groups: '1',
      with_progress: '1',
      with_awards: '1',
      with_links: '1',
      with_owners: '1',
      per_page: '999',
    };
    const url = `/users/${parentId}/members?${new URLSearchParams(queryParams)}`;
    try {
      const authResponse: AuthResponse = await makeRequest('GET', url);
      context.commit('updateChildren', authResponse.body as Array<Member>);
    } catch (error) {
      console.error(error);
    }
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  dismissedAwardBanners: (moduleState: ParentState) => getDismissedAwardBanners() || moduleState.dismissedAwardBanners,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
