import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import auth from '@/store/modules/auth';
import clubs from '@/store/modules/clubs';
import nav from '@/store/modules/nav';
import parent from '@/store/modules/parent';
import schemes from '@/store/modules/schemes';
import users from '@/store/modules/users';
import { state, mutations, getters, IState } from './root';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions: {
    resetState: async (context: ActionContext<IState, IState>) => {
      return Promise.all([
        context.dispatch('auth/resetState'),
        context.dispatch('clubs/resetState'),
        context.dispatch('nav/resetState'),
        context.dispatch('parent/resetState'),
        context.dispatch('schemes/resetState'),
      ]);
    },
  },
  modules: {
    auth,
    clubs,
    nav,
    parent,
    schemes,
    users,
  },
  getters,
});
