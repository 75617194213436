
import { Component, Vue, Prop } from 'vue-property-decorator';
import { bootstrap } from 'vue-gtag';
import { updateCookieConsent, getCookieConsent } from '@/services/utils';

@Component({
  name: 'CookieConsentBanner',
})
export default class CookieConsentBanner extends Vue {
  @Prop() private appType!: string; // parent, coach or club

  private showCookieBanner = false;

  created() {
    // Only show if they haven't visited the site before and analytics is enabled
    if (getCookieConsent(this.appType) === null && this.$gtag) {
      this.showCookieBanner = true;
    }
  }

  async updateCookie(value: boolean) {
    updateCookieConsent(this.appType, value);
    if (value) {
      await bootstrap();
    }
    this.showCookieBanner = false;
  }
}
